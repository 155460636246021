<template>
  <div class="row py-2 px-3 border-top align-items-md-center mx-0">
    <div class="col-md-2 col-4" :class="device === 'mobile' && 'px-1'">
      <b-form-select v-model="roomOccupation" :options="roomOccupationOptions"></b-form-select>
    </div>
    <div class="col-md-2 col-4" :class="device === 'mobile' && 'px-1'">
      <b-form-select v-model="roomKind">
        <b-form-select-option :value="cls.value" v-for="(cls, inx) in optionRoomClasses" :key="inx" v-b-tooltip.hover :title="cls.desc" >
          {{ cls.text }}
        </b-form-select-option>
      </b-form-select>
    </div>
    <div class="col-md-2 col-4" :class="device === 'mobile' && 'px-1'">
      <b-form-select v-model="basis" :options="optionBasis"></b-form-select>
    </div>
    <div class="col-12 text-center mt-1" :class="isEmalonMode || !isOdysseySite ? 'col-md-1' : 'col-md-2'">
      <h6 v-if="noHotels"></h6>
      <b-link v-else-if="!isEmalonMode || !isOdysseySite && remarks!==''" href="#" v-b-tooltip.hover="remarks" >
        {{ $t('search-result.terms-condition') }}
        <i class="fa fa-exclamation-circle ml-2" v-if="device==='mobile' && remarks!==''"/>
      </b-link>
    </div>
    <div class="col-md-3 d-flex flex-column" :class="[device==='mobile' && isEmalonMode || !isOdysseySite ? 'col-8' : 'col-12']" v-if="!isLoading">
      <h5 v-if="noHotels">{{ $t('search-result.no-hotels') }}</h5>
      <div v-else-if="isEmalonMode || !isOdysseySite">
        <p class="m-0"><s>₪{{ Math.round(currentHotel.total) }}</s></p>
        <p class="m-0">{{ $t('search-result.special-price') }}: <span class="price-letter-base"><strong>₪{{ Math.round(currentHotel.totalAfterDiscounted) }}</strong></span></p>
      </div>
      <div v-else-if="isFCLAgent" class="text-center">
        <span class="price-letter-base"><strong>{{ `₪${discountedPriceByManual} ` }}</strong></span>
        <span class="price-letter-commission" v-if="hotel.supplierCode !== 'ATLANTIS'"><strong>{{ `(${commission}%)` }}</strong></span>
        <!-- <span v-else><h5><strong> {{ `(₪${Math.round(currentHotel.total * (1 - commission / 100))}) ` }}</strong></h5></span> -->
      </div>
      <div v-else-if="currentHotel.supplierCode === 'ATLANTIS'">
        <p class="m-0">{{ $t('search-result.full-price') }}: <span class="price-letter-base"><strong>₪{{ discountedPriceByManual }}</strong></span></p>
        <p class="m-0">{{ $t('search-result.net-price') }}: <span class="price-letter-commission"><strong>₪{{ Math.round(currentHotel.total * (1 - 3 / 100)) }}</strong></span></p>
      </div>
      <div v-else>
        <p class="m-0">{{ $t('search-result.full-price') }}: <span class="price-letter-base"><strong>₪{{ discountedPriceByManual }}</strong></span></p>
        <p class="m-0">{{ $t('search-result.net-price') }}: <span class="price-letter-commission"><strong>₪{{ Math.round(currentHotel.total * (1 - commission / 100)) }}</strong></span></p>
      </div>

      <div v-if="isOdysseySite && hotel.supplierCode !== 'ATLANTIS'">
        <span v-if="isOdysseySite && hotel.supplierCode === 'ATLANTIS'">
          <i class="fas fa-check-circle bluefly"></i>
          <strong>{{ $t("product-page.remarks") }}:</strong>
          {{ hotel.remarkAgent }}
        </span>
        <span v-else-if="hotel.supplierCode === 'ATLANTIS'">
          <i class="fas fa-check-circle bluefly"></i>
          <strong>{{ $t("product-page.remarks") }}:</strong>
          {{ hotel.remark }}
        </span>
        <span v-if="hotel.adminRemark && hotel.supplierCode !== 'ATLANTIS'">
          <i class="fas fa-check-circle bluefly"></i>
          <strong>{{ $t("product-page.remarks") }}:</strong>
          {{ hotel.adminRemark }}
        </span>
        <span v-if="creditCardRemark && hotel.supplierCode !== 'ATLANTIS'">
          <i class="fas fa-check-circle bluefly"></i>
          <strong>{{ $t("product-page.remarks") }}:</strong>
          {{ creditCardRemark }}
        </span>
      </div>

      <div v-if="hotel.specialRemarks">
        <div v-for="(specialRemark, inx) in hotel.specialRemarks" :key="inx">
          <span v-if="specialRemark.displayPerPriceList">
            <strong>{{ specialRemark.remark }}</strong>
          </span>
        </div>
      </div>
    </div>
    <div v-if="isLoading" :class="['col-12 text-center', isEmalonMode || !isOdysseySite ? 'col-md-2' : 'col-md-1']"><b-spinner variant="primary"></b-spinner></div>
    <div v-else :class="['text-center', device==='mobile' ? 'col-4 mt-3' : 'col-12 mt-0', isEmalonMode || !isOdysseySite ? 'col-md-2' : 'col-md-1']" >
      <b-button v-if="!noHotels" variant="info" size="sm" @click="orderNow">{{ $t("search-result.order-now") }}</b-button>
    </div>
  </div>
</template>

<script>
import { BFormSelect, BLink, BButton,
  BFormSelectOption,
  BSpinner,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'moreHotelPrice',
  components: {
    BFormSelect,
    BLink,
    BButton,
    BFormSelectOption,
    BSpinner,
  },
  props: {
    hotel: {
      type: Object,
      default: null,
    },
    manualDiscount: {
      type: Number,
      default: 0,
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    supplierCode: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: null,
    },
    priceList: {
      type: String,
      default: null,
    },
    hotelCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      roomOccupation: `${this.$route.query.adult || 0},${this.$route.query.child || 0},${this.$route.query.baby || 0}`,
      oldRoomKind: null,
      roomKind: this.hotel.roomCode,
      basis: this.hotel.basis,
      oldBasis: null,
      query: this.$route.query,
      currentHotel: {},
      submitHotels: null,
      isLoading: false,
      suppliers: this.supplierCode,
      noHotels: false,
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
      marketerId: 'GET_MARKETER_ID',
      partnerId: 'GET_PARTNER_ID',
      subMarketerId: 'GET_SUB_AGENCY',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      isEmalonMode: 'GET_IS_EMALON_MODE',
    }),
    roomTypeData() {
      return {
        single: [
          ['1,0,0', 'single'],
          ['1,1,0', 'single-child'],
          ['1,2,0', 'single-2-child'],
          ['1,0,1', 'single-1-baby'],
          ['1,0,2', 'single-2-baby'],
        ],
        pair: [
          ['2,0,0', 'pair'],
          ['2,0,1', 'couple-1-baby'],
          ['2,0,2', 'couple-2-baby'],
          ['2,1,0', 'couple-child'],
          ['2,1,1', 'couple-child-baby'],
          ['2,1,2', 'couple-child-2-baby'],
          ['2,2,0', 'couple-2-child'],
          ['2,3,0', 'couple-3-child'],
          ['2,4,0', 'couple-4-child'],
          ['2,2,1', 'couple-2-child-baby'],
          ['2,2,2', 'couple-2-child-2-baby'],
        ],
        childrenRoom: [
          ['0,2,0', 'children-2'],
          ['0,3,0', 'children-3'],
        ],
        // couple2Rooms: [
        //   ['2,2,0,2', 'couple-2-children-2-rooms'],
        //   ['2,3,0,2', 'couple-3-children-2-rooms'],
        // ],
        three: [
          ['3,0,0', 'three-adult'],
          ['3,0,1', 'three-baby'],
          ['3,0,2', 'three-2-baby'],
          ['3,1,0', 'three-adult-child'],
          ['3,2,0', 'three-adult-2-child'],
        ],
        more: [
          ['4,0,0', 'four-adult'],
          ['5,0,0', 'five-adult'],
          ['6,0,0', 'six-adult'],
          ['7,0,0', 'seven-adult'],
          ['4,1,0', 'four-adult-child'],
        ],
      };
      // return [
      //   ['2,0', 'pair'],
      //   ['2,1', 'couple-child'],
      //   ['2,2', 'couple-2-child'],
      //   ['2,3', 'couple-3-child'],
      //   ['2,4', 'couple-4-child'],
      //   ['1,0', 'single'],
      //   ['1,1', 'single-child'],
      //   ['1,2', 'single-2-child'],
      //   ['3,0', 'three-adult'],
      //   ['3,1', 'three-adult-child'],
      //   ['3,2', 'three-adult-2-child'],
      //   ['4,0', 'four-adult'],
      //   ['5,0', 'five-adult'],
      //   ['6,0', 'six-adult'],
      //   ['7,0', 'seven-adult'],
      // ];
    },
    roomOccupationOptions() {
      const { roomTypeData } = this;
      const groupArray = Object.keys(roomTypeData);

      return groupArray.map((gKey) => ({
        label: this.$t(`room-type-list.${gKey}`),
        options: roomTypeData[gKey].map((item) => ({ value: item[0], text: this.$t(`room-type-list.${item[1]}`) })),
      }));
    },
    optionRoomClasses() {
      const { submitHotels } = this;
      const hotels = submitHotels || this.hotel.restHotels;
      if (hotels.length === 0) {
        return [];
      }
      const roomCodes = [
        ...new Set(hotels.map((item) => item.roomCode)),
      ].filter((code) => code.toUpperCase() !== 'STPCN');

      const options = roomCodes.map((elm) => ({
        value: elm,
        text: hotels.find((elem) => elem.roomCode === elm).roomName,
        desc: hotels.find((elem) => elem.roomCode === elm).roomDesc,
      }));
      return options;
    },
    optionBasis() {
      const { submitHotels } = this;
      const hotels = submitHotels || this.hotel.restHotels;
      const { roomKind } = this;
      if (hotels.length === 0) {
        return [];
      }
      const basis = [
        ...new Set(hotels.filter((elm) => (elm.roomCode === roomKind)).map((elm) => elm.basis)),
      ];
      const options = basis.map((elm) => {
        const lowerCode = elm.toLowerCase().trim();
        const translatedString = this.$t(`basis-name.${lowerCode}`);

        return {
          value: elm,
          text: translatedString.includes('basis-name') ? elm : translatedString,
        };
      });
      return options;
    },
    discountedPriceByManual() {
      const { manualDiscount, currentHotel } = this;
      const { total } = currentHotel;
      return Math.round(total * (1 - manualDiscount / 100));
    },
    queryBody() {
      const { roomOccupation } = this;
      const adult = roomOccupation.split(',')[0];
      const child = roomOccupation.split(',')[1];
      const infant = roomOccupation.split(',')[2];
      const rooms = roomOccupation.split(',')[3] || '';

      const body = {
        // city: this.supplierCode === 'ATLANTIS' ? this.hotel.mHotelInfo?.mainCityCode : this.hotel.city,
        city: this.hotel.mHotelInfo?.mainCityCode,
        hotelCode: this.hotelCode,
        checkIn: this.hotel.checkIn,
        checkOut: this.hotel.checkOut,
        adult: +adult,
        child: +child,
        infant: +infant,
        rooms: +rooms,
        lang: this.query.lang,
        includeFlight: this.query.includeFlight || null,
        returnLog: null,
        manualDiscount: this.manualDiscount,
      };
      if (this.supplierCode === 'ATLANTIS') body.category = this.category;
      return body;
    },
    isCardRemark() {
      let stateCardRemark = false;
      if (this.hotel?.specialRemarks) {
        this.hotel.specialRemarks.forEach((el) => {
          if (el.remarkCategory === 'cardRemark') stateCardRemark = true;
        });
      }
      return stateCardRemark;
    },
    isRemarkWithDisc() {
      if (!this.hotel?.specialRemarks) return false;
      if (!this.hotel?.specialRemarks?.length) return false;
      return this.hotel.specialRemarks.some((el) => el.remarkCategory === 'remarkWithDisc');
    },
    commission() {
      const { isFCLAgent } = this;
      const fcCommPctFromRule = this.hotel.fcCommPctFromRule || 5;
      if (this.hotel.supplierCode === 'ATLANTIS') return 3; // fcCommPctFromRule;
      const transientCommission = this.hotel?.hotelInfo?.extraCommission || 0;
      let cms = 0;
      if (isFCLAgent && this.hotel.matchingDiscInfo?.creditCardRemark) {
        cms = 10;
      } else if (this.hotel.matchingDiscInfo?.creditCardRemark) {
        cms = fcCommPctFromRule;
      } else if (this.isRemarkWithDisc) {
        cms = this.hotel?.specialRemarks.find((el) => el.agentCommissionPct).agentCommissionPct || 0;
        if (!isFCLAgent) {
          cms -= fcCommPctFromRule;
        }
      } else if (isFCLAgent && this.isCardRemark) {
        cms = 10;
      } else if (!isFCLAgent && !this.isCardRemark) {
        // cms = fcCommPctFromRule;
        cms = +this.hotel.mHotelInfo.commission - fcCommPctFromRule;
      } else if (isFCLAgent) {
        cms = (transientCommission + (this.hotel.mHotelInfo?.commission || 0)) || this.hotel.mHotelInfo?.commission || 0;
      } else if (this.hotel.agentCommPctFromRule) {
        cms = this.hotel.agentCommPctFromRule;
      } else {
        cms = Number(this.hotel.mHotelInfo.commission + transientCommission) - fcCommPctFromRule;
      }

      return cms;
    },
    creditCardRemark() {
      return this.hotel.matchingDiscInfo?.creditCardRemark?.replaceAll('$', '');
    },
    remarks() {
      let strRemarks = '';

      if (this.currentHotel?.remark !== '' && this.currentHotel?.remark !== null) {
        strRemarks += `${this.$t('search-result.terms')}: \r\n ${this.currentHotel.remark} \r\n \r\n`;
      }

      if (this.currentHotel?.cancelRemarks !== '' && this.currentHotel?.cancelRemarks !== null) {
        strRemarks += `${this.$t('search-result.cancellation-policy')}: \r\n ${this.currentHotel.cancelRemarks}`;
      }
      return strRemarks.replaceAll('\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r', '\n');
    },
  },
  watch: {
    roomOccupation() {
      this.getSubmitHotelsFromServer();
    },
    optionRoomClasses() {
      if (this.optionRoomClasses.length === 0) {
        this.noHotels = true
      }
    },
    roomKind() {
      this.oldRoomKind = this.roomKind;
      if (this.noHotels) {
        this.basis = null;
      } else {
        this.basis = this.oldBasis && this.optionBasis.findIndex((bss) => bss.value === this.oldBasis) ? this.oldBasis : this.optionBasis[0].value;
      }
      this.setCurrentHotel();
    },
    basis() {
      this.oldBasis = this.basis;
      this.setCurrentHotel();
    },
    hotel() {
      this.roomKind = this.hotel.roomCode;
      this.basis = this.hotel.basis;
    },
  },
  created() {
    this.currentHotel = this.hotel;
  },
  mounted() {
    setTimeout(() => {
      this.roomKind = this.hotel.roomCode;
      this.basis = this.hotel.basis;
      this.oldRoomKind = this.roomKind;
      this.oldBasis = this.basis;
    }, 1000);
  },
  methods: {
    setCurrentHotel() {
      const { roomKind, basis, hotel, submitHotels, category } = this;
      const { restHotels } = hotel;
      const hotelList = submitHotels || restHotels;
      if (!hotelList || !hotelList.length) {
        this.currentHotel = hotel;
        return;
      }

      this.currentHotel = hotelList.find((one) => (one.roomCode === roomKind && one.basis === basis && one.category === category));
      if (this.currentHotel === undefined) { this.noHotels = true; }
    },
    async getSubmitHotelsFromServer() {
      const body = this.queryBody;
      body.subCalling = true;
      body.suppliers = [this.supplierCode];

      if (!body.city || !body.checkIn || !body.checkOut || !body.adult || !body.suppliers) {
        this.$bvModal.msgBoxOk(
          this.$t('search-result.dont-remove-query'),
          {
            title: this.$t('product-page.expire-title'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return;
      }

      this.isLoading = true;
      const response = await this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', body);
      this.isLoading = false;
      this.submitHotels = response[0].data.data.filter((ht) => ht.priceList === this.priceList);
      this.noHotels = this.submitHotels.length === 0;

      if (this.noHotels) {
        this.roomKind = null;
        this.basis = null;
      } else {
        this.roomKind = this.oldRoomKind && this.optionRoomClasses.findIndex((cls) => cls.value === this.oldRoomKind) > -1 ? this.oldRoomKind : this.optionRoomClasses[0].value;
        this.basis = this.oldBasis && this.optionBasis.findIndex((bss) => bss.value === this.oldBasis) ? this.oldBasis : this.optionBasis[0].value;
      }

      this.oldRoomKind = this.roomKind;
      this.oldBasis = this.basis;

      this.setCurrentHotel();
    },
    orderNow() {
      const { marketerId, subMarketerId, partnerId } = this;
      let body = this.queryBody;
      if (marketerId && marketerId !== '') body = { ...body, marketerId };
      if (subMarketerId && subMarketerId !== '') body = { ...body, subMarketerId };
      if (partnerId) body = { ...body, partnerId };
      body.roomCode = this.roomKind;
      body.basisCode = this.basis;
      body.priceList = this.priceList;
      body.suppliers = this.supplierCode;

      if (window.location.host.includes('localhost')) {
        this.$router.push({ path: '/product-page', query: body });
      } else {
        const routeData = this.$router.resolve({ path: '/product-page', query: body });
        window.open(routeData.href, '_blank');
      }
    },
  },
};
</script>
<style scoped>
.category-d-back .border-top{
  border-color: #f5c6cb !important;
}
.bluefly {
    color: #2486c2;
    width: 20px;
}
</style>

<style>
  .tooltip-inner {
    white-space: pre-line;
    max-width: 350px;
    text-align: right;
  }
  span.price-letter-base {
    font-size: 2rem;
  }
  span.price-letter-commission {
    font-size: 1.5rem;
  }
</style>
